import React, { useState, useEffect, useRef } from 'react';
import Home from './components/Home';
import Navbar from './components/Navbar';
import Chat from './components/Chat';
import About from './components/About';
import Projects from './components/Projects';
import Experience from './components/Experience';
import Contact from './components/Contact';
import Hobbies from './components/Hobbies';
import Education from './components/Education';
import { initTheme, toggleTheme } from './themeManager'

function App() {
  const [theme, setTheme] = useState(initTheme());
  const scrollRef = useRef(window.scrollY);

  useEffect(() => {
    const handleStorageChange = () => {
      setTheme(initTheme());
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const handleThemeToggle = (event) => {
    event.preventDefault();
    scrollRef.current = window.scrollY;
    toggleTheme();
    setTheme(prevTheme => (prevTheme === 'light' ? 'dark' : 'light'));
    window.scrollTo(0, scrollRef.current);
  };

  return (
    <div className="App">
      <Navbar theme={theme} onThemeToggle={handleThemeToggle} />
      <Home id = "home" theme={theme} />
      <Chat theme={theme} />
      <section id="about" className="min-h-screen">
        <About theme={theme} />
      </section>
      <section id="education" className="min-h-screen">
        <Education theme={theme} />
      </section>
      <section id="projects" className="min-h-screen">
        <Projects theme={theme} />
      </section>
      <section id="experience" className="min-h-screen">
        <Experience theme={theme} />
      </section>
      <section id="hobbies" className="min-h-screen">
        <Hobbies theme={theme} />
      </section>
      <section id="contact" className="min-h-screen">
        <Contact theme={theme} />
      </section>
    </div>
  );
}

export default App;