import React, { useState, useEffect, useRef, useCallback } from 'react';
import qt from '../assets/qt.png';
import goatswipes from '../assets/goatswipes.png';
import calculator from '../assets/calculator.jpg';
import tetris from '../assets/tetris.png';

const ProjectCard = ({
  src,
  link,
  h3,
  hoverText,
  showDescription,
  onLearnMoreClick,
  onCloseClick,
  theme,
}) => {
  return (
    <div className="relative mx-auto group">
      <h3 className="text-xl font-semibold my-2 text-center">{h3}</h3>
      <div className="relative w-64 h-auto rounded-lg overflow-hidden">
        <img src={src} alt={`${h3} logo`} className="w-full h-auto rounded-lg" />
        <div className="h-12 flex justify-center items-center mt-4">
          {!showDescription && (
            <button
              onClick={onLearnMoreClick}
              className={`px-4 py-2 rounded transition-opacity duration-300 ${
                theme === 'light'
                  ? 'bg-white border border-gray-300 text-black'
                  : 'bg-gray-700 border border-gray-500 text-white'
              }`}
            >
              Learn More
            </button>
          )}
        </div>
        {showDescription && (
          <div
            className={`absolute bottom-0 left-0 w-full h-1/2 ${
              theme === 'light'
                ? 'bg-white bg-opacity-80'
                : 'bg-gray-800 bg-opacity-80'
            } transition-transform duration-500 ease-in-out flex flex-col justify-center items-center`}
          >
            <button
              onClick={onCloseClick}
              className={`absolute top-2 right-2 rounded-full w-6 h-6 flex items-center justify-center ${
                theme === 'light' ? 'bg-gray-700 text-white' : 'bg-gray-300 text-black'
              }`}
            >
              ×
            </button>
            <p className="text-center px-4">{hoverText}</p>
            <a
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              className="mt-4 text-blue-500 underline"
            >
              Visit Project
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

const Projects = ({ theme }) => {
  const [currentIndex, setCurrentIndex] = useState(1);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [openDescriptionIndex, setOpenDescriptionIndex] = useState(null);
  const [manualTransition, setManualTransition] = useState(false);

  const slides = [
    {
      src: qt,
      link: 'https://github.com/huyho1/CalendarApp',
      h3: 'Calendar Planning App',
      hoverText: 'C++',
    },
    {
      src: goatswipes,
      link: 'https://github.com/huyho1/GoatSwipes',
      h3: 'Food Transaction Website',
      hoverText: 'HTML, CSS, Javascript, Python',
    },
    {
      src: calculator,
      link: 'https://github.com/huyho1/2DGraphingCalculator',
      h3: '2D Graphing Calculator',
      hoverText: 'Java',
    },
    {
      src: tetris,
      link: 'https://github.com/huyho1/Tetris',
      h3: 'Tetris',
      hoverText: 'Python',
    },
  ];

  const totalSlides = slides.length;
  const carouselInnerRef = useRef(null);

  const nextSlide = useCallback(
    (manual = false) => {
      if (!isTransitioning) {
        setIsTransitioning(true);
        setManualTransition(manual);
        setOpenDescriptionIndex(null); // Close any open description when sliding
        if (currentIndex === totalSlides) {
          setCurrentIndex(1);
        } else {
          setCurrentIndex((prevIndex) => prevIndex + 1);
        }
      }
    },
    [isTransitioning, currentIndex, totalSlides]
  );

  const prevSlide = useCallback(
    (manual = false) => {
      if (!isTransitioning) {
        setIsTransitioning(true);
        setManualTransition(manual);
        setOpenDescriptionIndex(null); // Close any open description when sliding
        if (currentIndex === 1) {
          setCurrentIndex(totalSlides);
        } else {
          setCurrentIndex((prevIndex) => prevIndex - 1);
        }
      }
    },
    [isTransitioning, currentIndex, totalSlides]
  );

  const goToSlide = (index) => {
    if (!isTransitioning) {
      setIsTransitioning(true);
      setManualTransition(true);
      setOpenDescriptionIndex(null);
      setCurrentIndex(index);
    }
  };

  const handleLearnMoreClick = (index) => {
    setOpenDescriptionIndex(index);
    setIsPaused(true);
  };

  const handleCloseClick = () => {
    setOpenDescriptionIndex(null);
    setIsPaused(false);
  };

  useEffect(() => {
    const handleTransitionEnd = () => {
      setIsTransitioning(false);

      if (carouselInnerRef.current) {
        if (currentIndex > totalSlides) {
          carouselInnerRef.current.style.transition = 'none';
          setCurrentIndex(1);
        } else if (currentIndex === 0) {
          carouselInnerRef.current.style.transition = 'none';
          setCurrentIndex(totalSlides);
        }
      }
    };

    const currentRef = carouselInnerRef.current;
    if (isTransitioning && currentRef) {
      currentRef.addEventListener('transitionend', handleTransitionEnd);
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener('transitionend', handleTransitionEnd);
      }
    };
  }, [isTransitioning, currentIndex, totalSlides]);

  useEffect(() => {
    if (!isPaused) {
      const intervalId = setInterval(() => {
        if (!isTransitioning) {
          nextSlide();
        }
      }, 3000);
      return () => clearInterval(intervalId);
    }
  }, [isTransitioning, isPaused, nextSlide]);

  return (
    <div
      className={`min-h-screen pt-24 py-16 ${
        theme === 'light' ? 'bg-light-bg text-light-text' : 'bg-dark-bg text-dark-text'
      } px-8 lg:px-16`}
    >
      <div className="relative w-full max-w-3xl mx-auto overflow-hidden rounded-xl text-center mb-20">
        <h1 className="text-4xl font-bold my-8">Projects</h1>
        <div
          className="flex transition-transform ease mb-8"
          ref={carouselInnerRef}
          style={{
            transform: `translateX(-${currentIndex * 100}%)`,
            transition: isTransitioning
              ? `transform ${manualTransition ? '0.3s' : '0.9s'} ease`
              : 'none',
          }}
        >
          <div className="flex-none w-full flex justify-center items-center">
            <ProjectCard
              {...slides[totalSlides - 1]}
              showDescription={openDescriptionIndex === totalSlides - 1}
              onLearnMoreClick={() => handleLearnMoreClick(totalSlides - 1)}
              onCloseClick={handleCloseClick}
              theme={theme}
            />
          </div>
          {slides.map((slide, index) => (
            <div className="flex-none w-full flex justify-center items-center" key={index}>
              <ProjectCard
                {...slide}
                showDescription={openDescriptionIndex === index}
                onLearnMoreClick={() => handleLearnMoreClick(index)}
                onCloseClick={handleCloseClick}
                theme={theme}
              />
            </div>
          ))}
          <div className="flex-none w-full flex justify-center items-center">
            <ProjectCard
              {...slides[0]}
              showDescription={openDescriptionIndex === 0}
              onLearnMoreClick={() => handleLearnMoreClick(0)}
              onCloseClick={handleCloseClick}
              theme={theme}
            />
          </div>
        </div>
        <button
          className={`absolute top-1/2 transform -translate-y-1/2 left-2 ${
            theme === 'light' ? 'text-black' : 'text-white'
          } text-3xl md:text-4xl lg:text-5xl`}
          onClick={() => prevSlide(true)}
        >
          ‹
        </button>
        <button
          className={`absolute top-1/2 transform -translate-y-1/2 right-2 ${
            theme === 'light' ? 'text-black' : 'text-white'
          } text-3xl md:text-4xl lg:text-5xl`}
          onClick={() => nextSlide(true)}
        >
          ›
        </button>
        <div className="flex justify-center gap-2">
          {slides.map((_, index) => (
            <button
              key={index}
              className={`w-2 h-2 rounded-full ${
                index + 1 === currentIndex ? 'bg-blue-500' : 'bg-gray-300'
              }`}
              onClick={() => goToSlide(index + 1)}
            ></button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Projects;
